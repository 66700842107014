// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/dash.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".slice--our-mission .icon,.slice--our-mission img{height:3.75rem;margin:0 auto var(--spacing)}.slice--our-mission .icon{display:block}@media (min-width:48.0625em){.slice--our-mission .line{position:relative}.slice--our-mission .line:after,.slice--our-mission .line:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:repeat-x;background-size:auto 1px;content:\"\";height:100%;position:absolute;top:50%;transform:translateY(-50%)}.slice--our-mission .line:before{background-position:0 50%;left:-1.875rem;right:50%}.slice--our-mission .line:after{background-position:100% 50%;left:50%;right:-1.875rem}.slice--our-mission .circle{background:var(--tertiary-color);border:1px solid var(--secondary-color);border-radius:50%;height:.75rem;margin:0 auto;position:relative;transition:all .3s .3s;width:.75rem;z-index:1}.slice--our-mission__elements{position:relative}.slice--our-mission__elements .hover-line{background:var(--secondary-color);height:.0625rem;position:absolute;transition:all .3s ease-in-out}.slice--our-mission__element .rich-text{margin-left:auto;margin-right:auto;max-width:11.875rem}.slice--our-mission__element.first .circle{transition-delay:0s}.slice--our-mission__element.first .line:before,.slice--our-mission__element.last .line:after{content:none}}@media (min-width:48.0625em) and (hover:hover) and (pointer:fine){.slice--our-mission__element:hover .circle{background-color:var(--secondary-color)}}@media (min-width:48.0625em){.slice--our-mission__element:focus-visible .circle{background-color:var(--secondary-color)}}.slice--our-mission__element{padding:0 1.875rem}.slice--our-mission__element .rich-text strong{font-weight:400;-webkit-text-decoration:underline;text-decoration:underline}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
