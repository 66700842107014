<template>
  <slice class="slice--our-mission container">
    <prismic-title :title="slice.primary.title" class="h3 text-center mb-1" />
    <rich-text
      v-if="slice.primary.description.length > 0"
      :content="slice.primary.description"
      class="text-center mb-3 mb-md-4"
    />

    <div
      ref="elements"
      class="slice--our-mission__elements row-md row-no-wrap justify-center"
      @mouseleave="mouseLeave()"
    >
      <div
        v-for="(item, index) in slice.items"
        ref="element"
        :key="`item-${index}`"
        class="slice--our-mission__element text-center mb-2 mb-md-0"
        :class="{ first: index === 0, last: index === lastIndex }"
        @mouseenter="mouseenter(index)"
      >
        <icon v-if="item.with_icon && item.icon" :name="item.icon" />
        <lazy-image v-else-if="item.image" :image="item.image" />

        <p v-if="item.title.length > 0" class="w-600 mb-0-4 mb-md-0">
          {{ item.title[0].text }}
        </p>

        <div class="line mt-md-2 mb-md-2">
          <div class="circle"></div>
        </div>

        <rich-text
          v-if="item.desc.length > 0"
          :content="item.desc"
          class="text-small"
        />
      </div>

      <div ref="lineHover" class="hover-line"></div>
    </div>
  </slice>
</template>

<script>
import Slice from '@/components/Slices/Slice'
import PrismicTitle from '@/components/Title/PrismicTitle'
import RichText from '@/components/RichText/RichText'
import LazyImage from '@/components/Image/LazyImage'
import Icon from '@/components/icons/Icon'

export default {
  name: 'OurMissionSlice',
  components: {
    Slice,
    PrismicTitle,
    RichText,
    LazyImage,
    Icon,
  },
  props: {
    slice: { type: Object, required: true },
  },
  computed: {
    lastIndex() {
      return this.slice.items.length - 1
    },
  },
  methods: {
    mouseenter(index) {
      this.setLineInitialPosition()

      if (this.$refs.elements && this.$refs.element && this.$refs.lineHover) {
        const el = this.$refs.element[index]

        const boundCircle = el
          .getElementsByClassName('circle')[0]
          .getBoundingClientRect()
        const boundElements = this.$refs.elements.getBoundingClientRect()

        this.$refs.lineHover.style.right = `${
          boundElements.right - boundCircle.right
        }px`
      }
    },
    mouseLeave() {
      this.$refs.lineHover.style.right = '100%'
    },
    setLineInitialPosition() {
      if (this.$refs.elements && this.$refs.element && this.$refs.lineHover) {
        const first = this.$refs.element[0]

        const boundCircle = first
          .getElementsByClassName('circle')[0]
          .getBoundingClientRect()
        const boundElements = this.$refs.elements.getBoundingClientRect()

        this.$refs.lineHover.style.left = `${boundCircle.x - boundElements.x}px`
        this.$refs.lineHover.style.top = `${
          boundCircle.y - boundElements.y + boundCircle.height / 2
        }px`
      }
    },
  },
}
</script>

<style lang="scss">
.slice--our-mission {
  img {
    height: rem(60px);
    margin: 0 auto var(--spacing);
  }

  .icon {
    display: block;
    margin: 0 auto var(--spacing);
    height: rem(60px);
  }

  @include mq($from: tablet) {
    .line {
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        height: 100%;
        transform: translateY(-50%);
        background-image: url('~/assets/img/dash.svg');
        background-size: auto 1px;
        background-repeat: repeat-x;
      }

      &::before {
        left: rem(-$spacing * 2);
        right: 50%;
        background-position: 0 50%;
      }

      &::after {
        left: 50%;
        right: rem(-$spacing * 2);
        background-position: 100% 50%;
      }
    }

    .circle {
      position: relative;
      width: rem(12px);
      height: rem(12px);
      border: 1px solid var(--secondary-color);
      margin: 0 auto;
      border-radius: 50%;
      background: var(--tertiary-color);
      z-index: 1;
      transition: all 0.3s 0.3s;
    }

    &__elements {
      position: relative;

      .hover-line {
        position: absolute;
        height: rem(1px);
        background: var(--secondary-color);
        transition: all 0.3s ease-in-out;
      }
    }

    &__element {
      .rich-text {
        max-width: rem(190px);
        margin-left: auto;
        margin-right: auto;
      }

      &.first {
        .circle {
          transition-delay: 0s;
        }

        .line {
          &::before {
            content: none;
          }
        }
      }

      &.last {
        .line {
          &::after {
            content: none;
          }
        }
      }

      @include on-hover-and-focus {
        .circle {
          background-color: var(--secondary-color);
        }
      }
    }
  }

  &__element {
    padding: 0 rem($spacing * 2);

    .rich-text {
      strong {
        font-weight: 400;
        text-decoration: underline;
      }
    }
  }
}
</style>
